html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

body {
  background-color: black;
  color: black;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

a {
  color: black;
  text-decoration: none;
}
a:hover {
  color: gray;
}

.Layer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.Layer__image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
}

.Hue--0 {
  filter: hue-rotate(0deg);
}
.Hue--90 {
  filter: hue-rotate(90deg);
}
.Hue--180 {
  filter: hue-rotate(180deg);
}
.Hue--270 {
  filter: hue-rotate(270deg);
}
.Hue--360 {
  filter: hue-rotate(360deg);
}

.Indicator {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  border: 1em solid rgb(202, 202, 202);
}

@media (max-width: 800px) {
  .Indicator {
    font-size: 0.75rem;
  }
}

@media (max-width: 640px) {
  .Indicator {
    font-size: 0.5833rem;
  }
}
